import { isMobile } from "./device-type-detector";
import { UtmValues } from "./models/utm-values";
import { storageService } from "./storage-service";

function getUtmsOrDefault(params: URLSearchParams): UtmValues {
    let utms: UtmValues = {
        referrer: document.referrer,
        device: params.get('network') || (isMobile() ? 'm' : 'd'),
        landingPage: window.location.href,
        timestamp: new Date().toISOString(),
        // undefined will be removed by JSON.stringify
        utmMedium: params.get('utm_medium') || undefined,
        utmSource: params.get('utm_source') || undefined,
        utmCampaign: params.get('utm_campaign') || undefined,
        utmContent: params.get('utm_content') || undefined,
        utmTerm: params.get('utm_term') || undefined,
        ppcKeyword: params.get('keyword') || undefined,
        ppcPlacement: params.get('placement') || undefined,
        trafficType: params.get('traffictype') || undefined,
        affiliateId: params.get('irclickid') || params.get('affiliateid') || undefined,
        channelId: params.get('channelid') || undefined
    };
    
    ['gclid', 'fbclid', 'liclid', 'msclkid'].forEach(clickId => {
        if(!params.has(clickId)) return;
        utms.platformId = params.get(clickId) || undefined;
    });
    return utms;
}

function getPactId(params: URLSearchParams) : string | undefined {
    const pact = /^pact$/i; // case insensetive match
    for(const [key, val] of params) {
        if(pact.test(key)) return val;
    }
}

export function captureUtms() {
    const hasReferrer = document.referrer.length > 0;
    const params = new URLSearchParams(window.location.search);
    if(!storageService.hasLastTouchValues() || (hasReferrer && !document.referrer.includes('bill.com')) || params.has('utm_medium')) {
        storageService.setLastTouchValues(getUtmsOrDefault(params));
    }

    if(!storageService.hasFirstTouchValues()) {
        storageService.setIsFirstTouchSession(true);
        storageService.setFirstTouchValues(getUtmsOrDefault(params));
    }

    const pactId = getPactId(params);
    if(pactId) storageService.setLastTouchPactId(pactId);

    //more than one click id is unlikely to be present in a given session
    ['gclid', 'fbclid', 'liclid', 'msclkid'].forEach(clickId => {
        if(!params.has(clickId)) return;
        const clickIds = (storageService.getClickIds() || {}) as any;
        if(!(clickId in clickIds)) {
            clickIds[clickId] = params.get(clickId);
            storageService.setClickIds(clickIds);
        }
    });

    // Referral Rock REFERRALCODE
    const code = params.get('REFERRALCODE');
    if(code) {
        storageService.setReferralRockCode(code);
    }

    if(params.has('offerid')) storageService.setOfferId(params.get('offerid')!);
}