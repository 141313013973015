import { storageService } from './storage-service';

export function configureInputs(form: HTMLFormElement) {
    // handle checked state for radio buttons
    form.querySelectorAll<HTMLInputElement>('input[type=radio][data-radio-checked]').forEach(el => {
        el.checked = true;
        el.previousElementSibling?.classList.add('w--redirected-checked');
    });
    
    // set custom values for checkboxes
    form.querySelectorAll<HTMLInputElement>('input[type=checkbox][data-checkbox-value]').forEach(checkbox => {
        checkbox.value = checkbox.dataset.checkboxValue!;
    });

    addPhoneValidator(form);

    // toggle accounting software field visibility
    toggleAccountingSoftware(form);
    form.querySelectorAll<HTMLInputElement>('input[name=formoptions],input[name=productInterestCheckbox]').forEach(input => {
        input.addEventListener('change', () => {                    
            toggleAccountingSoftware(form);
            if(input.name === 'productInterestCheckbox')
                toggleProductInterestError(form, getProductInterestOrNull(form) === '')
        });
    });

    const accountingSoftware = form.querySelector<HTMLSelectElement>('select#accountingSoftware');    
    const msDynamics = form.querySelector<HTMLSelectElement>('select#msDynamicsOption');
    // toggle ms dynamics field visibility
    accountingSoftware?.addEventListener('change', () => {
        if (!msDynamics) return;
        const hidden = accountingSoftware.disabled || accountingSoftware.value !== 'msdynamics';
        msDynamics.classList.toggle('hide', hidden);
        msDynamics.disabled = hidden;
        msDynamics.required = !hidden;
    });
}

function addPhoneValidator(form: HTMLFormElement) {
    form.querySelectorAll<HTMLInputElement>('input[type=tel]').forEach(el => {
        el.addEventListener('change', () => {
            if(!el.value) return; // do not validate if the field is empty (in case of optional field)      
            const digits = el.value.replace(/\D+/g, '');
            // ensure NANP format and valid area code
            const isValid = /[0-9]{3}[2-9][0-9]{6}/g.test(digits) && 
                            areaCodes.includes(digits.substring(0, 3))
            el.setCustomValidity(isValid ? '' : 'Invalid phone number');
            el.reportValidity();
        });
    });

    const areaCodes = [
        '201','202','203','204','205','206','207','208','209','210','212','213','214',
        '215','216','217','218','219','220','223','224','225','226','227','228','229',
        '231','234','236','239','240','242','246','248','249','250','251','252','253',
        '254','256','260','262','263','264','267','268','269','270','272','274','276',
        '278','279','281','283','284','289','301','302','303','304','305','306','307',
        '308','309','310','312','313','314','315','316','317','318','319','320','321',
        '323','325','326','327','330','331','332','334','336','337','339','340','341',
        '343','345','346','347','351','352','354','360','361','364','365','367','368',
        '369','380','382','385','386','387','401','402','403','404','405','406','407',
        '408','409','410','412','413','414','415','416','417','418','419','423','424',
        '425','428','430','431','432','434','435','437','438','440','441','442','443',
        '445','447','448','450','456','458','463','464','468','469','470','472','473',
        '474','475','478','479','480','484','500','501','502','503','504','505','506',
        '507','508','509','510','512','513','514','515','516','517','518','519','520',
        '521','522','523','524','525','526','527','528','529','530','531','532','533',
        '534','535','538','539','540','541','542','543','544','545','546','547','548',
        '549','550','551','552','553','554','555','556','557','558','559','561','562',
        '563','564','566','567','569','570','571','572','573','574','575','577','578',
        '579','580','581','582','584','585','586','587','588','589','600','601','602',
        '603','604','605','606','607','608','609','610','612','613','614','615','616',
        '617','618','619','620','622','623','626','627','628','629','630','631','633',
        '636','639','640','641','644','646','647','649','650','651','655','656','657',
        '658','659','660','661','662','664','667','669','670','671','672','677','678',
        '679','680','681','682','683','684','688','689','700','701','702','703','704',
        '705','706','707','708','709','710','712','713','714','715','716','717','718',
        '719','720','721','724','725','726','727','730','731','732','734','737','740',
        '742','743','747','753','754','757','758','760','762','763','764','765','767',
        '769','770','771','772','773','774','775','778','779','780','781','782','784',
        '785','786','787','800','801','802','803','804','805','806','807','808','809',
        '810','812','813','814','815','816','817','818','819','820','825','826','828',
        '829','830','831','832','833','835','838','839','840','843','844','845','847',
        '848','849','850','854','855','856','857','858','859','860','862','863','864',
        '865','866','867','868','869','870','872','873','876','877','878','879','888',
        '900','901','902','903','904','905','906','907','908','909','910','912','913',
        '914','915','916','917','918','919','920','925','927','928','929','930','931',
        '932','934','935','936','937','938','939','940','941','943','945','947','948',
        '949','951','952','954','956','959','970','971','972','973','975','978','979',
        '980','983','984','985','986','989'
    ];
}

export function toggleProductInterestError(form: HTMLFormElement, show: boolean) {
    const id = 'productInterest-reqError';
    const errorContainer = form.querySelector('div#productInterestSection')?.parentElement;
    if(!errorContainer) return;
    let errorEl = form.querySelector<HTMLDivElement>('#' + id);
    if(!errorEl) {
        errorEl = document.createElement('div');
        errorEl.id = id;
        errorEl.style.color = 'red';
        errorEl.style.fontSize = '0.9rem';
        errorEl.textContent = 'Required';
        errorContainer.appendChild(errorEl);
    }
    
    if (show) {
        errorContainer.style.border = '1px solid red';
        errorEl.hidden = false;
    } else {
        errorContainer.style.border = 'none';
        errorEl.hidden = true;
    }
}

export function getABTestVariances() : string {
    // capture A/B test values
    for(var i = 1, abVal, abVals = ''; i <= 15; i++)
        abVals += (abVal = localStorage.getItem('bdcAbTest' + i)) ? abVal + ' ' : '';
    return abVals;
}

export function disableSubmitButton(submit: HTMLElement | null, disable = true) {
    if(!submit || !(submit instanceof HTMLInputElement)) return;
    if(submit.disabled === disable) return;
    [submit.value, submit.dataset.wait] = [submit.dataset.wait || 'Processing...', submit.value];
    submit.disabled = disable;
}

export function enableSubmitButton(submit: HTMLElement | null) {
    disableSubmitButton(submit, false);
}

function toggleAccountingSoftware(form: HTMLFormElement) {
    const accountingSoftwareContainer = form.querySelector<HTMLElement>('#accountingSoftwareContainer');
    if(!accountingSoftwareContainer) return;
    const accountingSoftware = accountingSoftwareContainer.querySelector<HTMLSelectElement>('select#accountingSoftware');
    if(!accountingSoftware) return;

    const productInterest = getProductInterestOrNull(form);
    const hasProductInterest = productInterest !== null;
    // we hide software field in these cases:
    // it is console form/selection OR
    // product interest is present AND product interest does not contain AP and/or AR
    const hidden = isConsoleForm(form) || (hasProductInterest && !productInterest.includes('A'));
    accountingSoftwareContainer.classList.toggle('hide', hidden);
    accountingSoftware.disabled = hidden;
    accountingSoftware.dispatchEvent(new Event('change')); // forces toggle of ms dynamics
}

// returns null if there are no product interest checkboxes
export function getProductInterestOrNull(form: HTMLFormElement) : string | null {
    const productInterests = form.querySelectorAll<HTMLInputElement>('input[name=productInterestCheckbox]');
    if(productInterests.length) {
        let value = '';
        productInterests.forEach(el => {
            if(el.checked) value += el.value;
        });
        return value;
    } else return null;
}

export function isConsoleForm(form: HTMLFormElement) {
    let formType = form.querySelector<HTMLInputElement>('input[name=formoptions]:checked')?.value || form.dataset.signupForm || 'direct';
    return ['console', 'wmanagement'].includes(formType);
}

const IGNORE_INPUTS = ['hidden', 'radio', 'submit', 'button'];
export function saveFormData(form: HTMLFormElement) {
    const obj = storageService.getUserData() || {};
    for (let i = 0; i < form.elements.length; i++)  {
        const el = form.elements[i] as HTMLInputElement;
        // we don't want radios as it breaks the toggle of accounting software.
        if(el.nodeName !== 'INPUT' || IGNORE_INPUTS.includes(el.type)) continue;
        if(el.type === 'checkbox') {
            obj[el.id] = el.checked;
        } else {
            obj[el.name] = el.value;
        }
    }
    storageService.setUserData(obj);
}

export function populateFromStorage(form: HTMLFormElement) {
    const data = storageService.getUserData();
    if(!data || !form) return;
    for (let i = 0; i < form.elements.length; i++)  {
        const el = form.elements[i] as HTMLInputElement;
        if(el.nodeName !== 'INPUT' || IGNORE_INPUTS.includes(el.type)) continue;
        if(el.type === 'checkbox') {            
            const value = data[el.id];
            if(value !== undefined) {
                el.checked = value;
                notify(el);
            } 
        } else {
            const value = data[el.name];
            if(value) {
                el.value = value;
                notify(el);
            }
        }
        
    }
}

export function getNumberOfEmployees(form: HTMLFormElement) : number | null {
    const el = form.querySelector<HTMLInputElement>('[name=numberOfEmployees]');
    if(!el) return null;
    return Number(el.value);
}

function notify(el: any) {
    if('dispatchEvent' in el) {
	    const changeEvent = new Event('change', { bubbles: true });
        el.dispatchEvent(changeEvent);
    }
}